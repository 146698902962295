<template>
  <div class="content">
    <div class="title">
      <span style="font-size: 20px; font-weight: bold">{{ showUsername }}的数据看板</span>
      <a-range-picker v-model="rangeValue" :allowClear="false" @change="onRangeChange" />
    </div>
    <a-card class="card" title="经销商配合度分析">
      <div slot="extra">
        <a-tooltip>
          <template slot="title">
            <div>
              迟发布：设置了定时发布的笔记推送后，门店进行一键发布/分享发布时，没有早于定时时间一小时（立即发布的笔记没有迟发布）
            </div>
            <div>未发布：定时/立即发布的笔记推送后，门店当日24点前未进行一键发布/分享发布</div>
          </template>
          数据说明
        </a-tooltip>
      </div>
      <div style="display: inline-block">
        <a-card>
          <div class="card-title">总发布数/迟发布数/未发布数</div>
          <div>{{ dealerTotalPushCount }}/{{ dealerLatePublishedCount }}/{{ dealerUnpublishCount }}</div>
        </a-card>
      </div>
      <div class="chart">
        <Bar id="dealerChart" :chartData="dealerChartData" :originalData="dealerData" type="dealer" />
      </div>
    </a-card>
    <a-card v-if="isMaster" class="card" title="运营效率分析">
      <div slot="extra">
        <a-tooltip>
          <template slot="title">
            <div>迟完成：设置了运营计划后，运营在当日17:00后才进行百夫长推送/发布笔记</div>
            <div>未完成：设置了运营计划后，运营当日未针对该计划进行百夫长推送/发布笔记</div>
            <div>设置了运营计划后，在百夫长推送/发布笔记时需关联对应的运营计划，否则会记录为迟完成或未完成计划</div>
          </template>
          数据说明
        </a-tooltip>
      </div>
      <div style="display: inline-block">
        <a-card>
          <div class="card-title">总计划数/迟完成数/未完成数</div>
          <div>{{ operatorTotalPlanCount }}/{{ operatorLatePublishedCount }}/{{ operatorUnpublishCount }}</div>
        </a-card>
      </div>
      <div class="chart">
        <Bar id="operatorChart" :chartData="operatorChartData" :originalData="operatorData" type="operator" />
      </div>
    </a-card>
    <a-card class="card" title="任务分析">
      <div>
        <template v-if="taskList.total">
          <a-row gutter="10">
            <a-col style="margin-bottom: 10px" :span="6" v-for="(item, index) in taskList.list" :key="index">
              <a-card>
                <div class="card-title task-title">
                  <span class="task-name"
                    >{{ item.taskCycleType == 'WORK_DAY_REPEAT' ? '每日重复' : '不重复' }}-{{ item.taskName }}</span
                  >
                  <a-button type="link" @click="goCreateTask()">查看</a-button>
                </div>
                <div>{{ item.description || '无描述' }}</div>
                <div>任务参与率：{{ ((item.participatedCount / item.participationCount) * 100).toFixed() }}%</div>
              </a-card>
            </a-col>
          </a-row>
        </template>
        <div v-else>你还没有下发任务哦，快去<a @click="goCreateTask">任务列表</a>创建吧</div>
      </div>
    </a-card>
  </div>
</template>

<script>
import Bar from './Bar.vue';
import moment from 'moment';
import xhsAgencyApi from '@/api/xhsAgencyApi';

moment.updateLocale('en', {
  week: {
    dow: 1, // 星期一是每周的第一天
  },
});

export default {
  name: 'digitalOperation',
  props: {},
  components: { Bar },
  data() {
    return {
      isMaster: false,
      rangeValue: [moment().startOf('week'), moment().endOf('week')],
      dealerData: [],
      dealerChartData: { xData: [], yData: [] },
      operatorData: [],
      operatorChartData: { xData: [], yData: [] },
      taskList: {},
      groupData: [],
    };
  },
  computed: {
    username() {
      return localStorage.user_name;
    },
    showUsername() {
      return this.isMaster ? this.groupData?.[0]?.groupName : localStorage.enterprise_wechat_userName;
    },
    dealerTotalPushCount() {
      return this.dealerData.reduce((total, item) => total + item.totalPushCount, 0);
    },
    dealerLatePublishedCount() {
      return this.dealerData.reduce((total, item) => total + item.latePublishedCount, 0);
    },
    dealerUnpublishCount() {
      return this.dealerData.reduce((total, item) => total + item.unpublishCount, 0);
    },
    operatorTotalPlanCount() {
      return this.operatorData.reduce((total, item) => total + item.totalPlanCount, 0);
    },
    operatorLatePublishedCount() {
      return this.operatorData.reduce((total, item) => total + item.latePublishedCount, 0);
    },
    operatorUnpublishCount() {
      return this.operatorData.reduce((total, item) => total + item.unpublishCount, 0);
    },
  },
  watch: {},
  mounted() {
    this.getListGroupByMasterUsername();
    this.getDealerCooperationAnalysis();
    this.getTaskList();
  },
  methods: {
    getListGroupByMasterUsername() {
      const payload = {
        masterUsername: this.username,
      };
      xhsAgencyApi.getListGroupByMasterUsernameApi(payload).then((res) => {
        if (res.code === 200) {
          this.isMaster = res.data.length > 0 ? true : false;
          this.groupData = res.data;
          if (this.isMaster) {
            this.getOperatorEfficiencyAnalysis();
          }
        }
      });
    },
    getDealerCooperationAnalysis() {
      const payload = {
        startTime: moment(this.rangeValue[0]).format('YYYY-MM-DD 00:00:00'),
        endTime: moment(this.rangeValue[1]).format('YYYY-MM-DD 23:59:59'),
        username: this.username,
      };
      xhsAgencyApi.getDealerCooperationAnalysisApi(payload).then((res) => {
        if (res.code === 200) {
          this.dealerData = res.data;
          this.dealerChartData.xData = res.data.map((v) => v.memberName);
          this.dealerChartData.yData = res.data.map((v) => v.totalPushCount);
        }
      });
    },
    /*************  ✨ Codeium Command ⭐  *************/
    /**
     * Gets the operator efficiency analysis data.
     *
     * This function fetches the operator efficiency analysis data from the API and
     * updates the component's state with the response data. The response data is an
     * array of objects, where each object represents an operator and contains the
     * operator's name and the total number of plans for that operator.
     *
     * The function is called when the component is mounted and whenever the date
     * range changes.
     */
    /******  0fb86a6a-af4e-4dcd-aaf3-6cf42762a2bd  *******/ getOperatorEfficiencyAnalysis() {
      const payload = {
        startTime: moment(this.rangeValue[0]).format('YYYY-MM-DD 00:00:00'),
        endTime: moment(this.rangeValue[1]).format('YYYY-MM-DD 23:59:59'),
        username: this.username,
      };
      xhsAgencyApi.getOperatorEfficiencyAnalysisApi(payload).then((res) => {
        if (res.code === 200) {
          this.operatorData = res.data;
          this.operatorChartData.xData = res.data.map((v) => v.operatorName);
          this.operatorChartData.yData = res.data.map((v) => v.totalPlanCount);
        }
      });
    },
    getTaskList() {
      const payload = {
        creator: 'test-hxd',
        taskStatusList: ['IN_PROGRESS', 'NOT_STARTED'],
        page: 1,
        size: 9999,
      };
      xhsAgencyApi.getTaskList(payload).then((res) => {
        if (res.code === 200) {
          this.taskList = res.data;
        }
      });
    },
    goTaskDetail(id) {
      this.$router.push({ path: `/xhsAgency/dataCollectionDetail?id=${id}` });
    },
    goCreateTask() {
      this.$router.push({ path: '/xhsAgency/missionList' });
    },
    onRangeChange() {
      this.getDealerCooperationAnalysis();
      if (this.isMaster) {
        this.getOperatorEfficiencyAnalysis();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
}
.card-title {
  font-weight: bold;
}
.card {
  margin-top: 20px;
}
.task-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task-name {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
